import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import ButtonGetStarted from '../Buttons/ButtonGetStarted'
import ButtonWatchDemo from '../Buttons/ButtonWatchDemo'



const HeroSection = ({ slice }) => {
    const { primary } = slice
    const { heading, sub_heading } = primary

    const { Bimg1, Bimg2 } = useStaticQuery(
        graphql`
          query {
            Bimg1: file(relativePath: {eq: "Solutions/business-img1.png"}) {
                childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, width: 130, height: 350)
                }
            }
            Bimg2: file(relativePath: {eq: "Solutions/business-img.png"}) {
                childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, width: 360, height: 360)
                }
            }
          }
        `
    )
    return (
        <>
       
        <div className="background1 l lg:py-52 md:pt-40 md:pb-52 pt-40 pb-24 md:text-center text-left relative flex overflow-hidden">
           
            <div className="hero-blk absolute right-0 top-0 md:hidden block w-40">
                <GatsbyImage image={getImage(Bimg1)} width="130" height="350" alt={heading && heading.text} />
            </div>
            <div className="max-w-5xl mx-auto px-4 relative">
                <h1 className="lg:text-7xl sm:text-6xl text-4xl text-blue font-medium tracking-tighter mb-8">{heading && heading.text}</h1>
                <p className="text-blue text-xl md:mx-auto">{sub_heading && sub_heading}</p>
                <div className="flex md:justify-center justify-start md:items-center items-start md:flex-row flex-col md:gap-4">
                    <ButtonGetStarted />
                    <ButtonWatchDemo />
                </div>
                
            </div>
            <div className=" absolute right-0 -bottom-40 md:block hidden lg:w-96 w-80">
                <GatsbyImage image={getImage(Bimg2)} width="360" height="360" alt={heading && heading.text} />
            </div>
           
        </div>
       
            
            </>
    )
}
export default HeroSection

export const query = graphql`
  fragment solutionsBHeroSlice on PrismicSolutionsSmallbusinessDataBodyHero {
    id
    slice_type
    primary {
    heading {
        text
        html
    }
    sub_heading
    }
  }
`
