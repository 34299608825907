import React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import StripeSection from '../Elements/StripeSection';
import LinearContentBlock from '../Elements/LinearContentBlock';

const ContentBlks =  ({
  slice: {
    primary: {
      title,
      description,
    },
    items,
  },
}) => (
  <StripeSection title={title} description={description}>
    <div className="shipment-bg absolute md:left-0 right-0 bottom-0 -z-10" />
    <LinearContentBlock
      entries={items.map(({ heading, content, ...rest }) => ({
        title: heading?.text,
        content: content?.richText,
        ...rest,
      }))}
      shadow
    />
  </StripeSection>
);

export default ContentBlks;

export const query = graphql`
  fragment solutionsBContentSlice on PrismicSolutionsSmallbusinessDataBodySolutionsContent {
    id
    slice_type
    primary {
      title
      description { richText }
    }
    items {
      heading {
        text
        html
      }
      content {
        text
        html
        richText
      }
      image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
      button_text
      button_link
    }
  }
`
