import React from "react"
import { graphql } from "gatsby";

import CustomerExperience from "../Elements/CustomerExperience"
import Testimonial1 from "../Elements/Testimonials1"

const Testimonials =  ({ slice }) => {
  const { primary, items } = slice
  const { heading } = primary
  return (
    <>
      <div className="xl:pt-32 md:pt-24 pt-4 sm:pb-32 pb-28 overflow-hidden">
        <div className="ml-auto flex lg:flex-row flex-col relative">
          <div className="lg:w-1/3 w-full px-4">                    
            <h2 className="xl:w-96 lg:pl-4 lg:ml-auto xl:text-6xl xs:text-5xl text-4xl text-blue font-medium tracking-tighter md:mb-12 mb-6">
              {heading?.text}
            </h2>
          </div>
          <div className="lg:w-2/3 w-full relative">
            <Testimonial1 data={items} theme="white" />
          </div>
        </div>
      </div>
      <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
        <CustomerExperience items="clients" />
      </div>
    </>
  );
};

export default Testimonials;

export const query = graphql`
  fragment solutionsBTestimonialsSlice on PrismicSolutionsSmallbusinessDataBodyTestimonials {
    id
    slice_type
    primary {
      heading {
        text
        richText
      }
    }
    items {
      testimonial {
        document {
          ... on PrismicTestimonials {
            id
            data {
              author
              content
              designation
              image {
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
