import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/SolutionsSmallbusiness"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SolutionsSmallbusiness = (props) => {
  const { data } = props
  if (!data) return null
  const pageData = data.prismicSolutionsSmallbusiness
  const page = pageData.data || {}
  return(
    <Layout className="background1" theme={props.pageContext.header_theme && props.pageContext.header_theme}>
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />
    </Layout>
  );
};

export default SolutionsSmallbusiness

export const query = graphql`
  query prismicSolutionsSmallbusinessQuery($uid: String!){
    prismicSolutionsSmallbusiness(uid: {eq: $uid}) {
        uid
        id
        type
        data{
          body{
                ... on PrismicSliceType {
                  slice_type
                }
                ...solutionsBHeroSlice
                ...solutionsBIntegrationsSlice
                ...solutionsBContentSlice
                ...solutionsBTestimonialsSlice
            }
        }
    }
  }
`
