import HeroSection from "./HeroSection"
import Integrations from "../Elements/Integrations"
import ContentBlks from "./ContentBlks"
import Testimonials from "./Testimonials"

export const components = {
  hero: HeroSection,
  powerful_integrations: Integrations,
  solutions_content: ContentBlks,
  testimonials: Testimonials,
}
